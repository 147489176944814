// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const URL = 'https://portal.cdt-2500.np.isccloud.io';
const STAGE = 'cdt-2500';

export const environment = {
  production: true, //either true or false
  NG_APP_COGNITO_USERPOOL_ID: 'us-east-2_pB4PnupE7',
  NG_APP_COGNITO_CLIENT_ID: '5ulfm827ucet2qi7557t30qi4v',
  NG_APP_COGNITO_REGION: 'us-east-2',
  APP_URL: `${URL}`,
  API_URL: `${URL}/v3/api`,
  STAGE: `${STAGE}`,
  SQLAAS_URL: '#sqlaas_url#',
};
